<template>
    <div class="slider h-screen">
      <HeaderSlider :title="props.title" class="h-screen max-h-screen" v-slot="{currentSlide}">
          <Slide v-for="(slide, index) in carousel_slides" :key="index">
              <div 
              v-show="currentSlide === index + 1"
              class="content absolute w-full max-w-full">
                  <img class="min-w-full md:h-screen h-screen object-cover" :src="require('../../assets/images/webp/training/' + title.split('.')[1] + '.webp')">
              </div>
          </Slide>
      </HeaderSlider>
    </div>
  </template>
  
  <script setup>
  import HeaderSlider from '../Items/HeaderSlider.vue';
  import Slide from '../carousel/Slide.vue';
  import { ref, defineProps } from 'vue';
  
  // Carousel slide
  const carousel_slides = ref(['one', 'two', 'three']);

  const props = defineProps({
    title: {
        type: String,
        required: true,
        default: 'slider.welcomeInfutureSchool'
    }
})
  
  </script>
  
  <style  scoped>
  
  </style>>