<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div class="carousel md:h-screen h-screen">
        <slot :currentSlide="currentSlide" />

        <!-- Pagination and Navigation -->
        <div class="pagination z-100 absolute flex bottom-4 w-full justify-center items-center cursor-pointer">
            <a href="#ddd" :class="[
                'ml-10 transform text-white cursor-pointer rotate-0 icon-arrow',
            ]">
                <icon class="cursor-pointer" :data="ic_arrow" height="22" width="22" color="white" original />
        </a>
        </div>


        <!-- Slide text section -->
        <div class="typing-text-section z-10 mt-10 absolute h-screen md:w-full flex justify-center items-center">

            <div class="content-section md:p-0">

                <!-- Typing block  -->

                <p
                    class="title-section text-white font-extrabold md:text-xultra md:leading-0 leading-normal	text-5xl md:text-center text-center">
                    <VueWriter :array="array" :eraseSpeed="50" :typeSpeed="100" :iterations="1" />
                </p>
            </div>
        </div>


        <!-- Top header Section -->
        <div
            class="top-header md:flex hidden absolute bg-blue-principal w-full items-center justify-start h-11 pl-16 pr-16">
            <div class="email-and-phone text-xs w-full flex gap-10 text-grey-white font-thin">
                <div class="flex gap-3">
                    <p> {{ $t('topHeader.tel') }}</p>
                    <p>+221 33 843 87 15</p>
                </div>
                <div class="flex gap-3">
                    <p> {{ $t('topHeader.email') }}</p>
                    <p>contact@eclipse.holdings</p>
                </div>
            </div>
            <div class="login-section text-xs text-grey-white font-thin cursor-pointer">
                {{ $t('topHeader.login') }}
            </div>
        </div>

        <!-- Menu ml-72 -->
        <div class="absolute menu mt-x39 ml-72" v-if="showMenu" @mouseleave="showMenuPlease(false);">
            <div class="content flex">
                <!-- Menu items  -->
                <div :class="[
                    'flex flex-col gap-4 bg-white z-99',
                ]">
                    <div class="flex-one flex pl-8 pt-4 pr-8 pb-2 cursor-pointer spax items-center focus-within:text-xl"
                        @mouseover="showTranningPlease(true)">
                        <div class="w-full font-medium text-xl pr-10">Formations</div>
                        <div class=" text-3xl transform rotate-270">
                            <icon :data="icon_m" height="16" width="16" original />
                        </div>
                    </div>
                    <div class="flex-one flex pl-8 pt-2 pr-8 pb-2 cursor-pointer items-center focus-within:text-xl"
                        @mouseover="showTranningPlease(false)">
                        <div class="w-full font-medium text-xl">
                            <a href="#presidentWord" class="text-black-principal"> {{ $t('menu.presidentWord') }}</a>
                        </div>
                    </div>
                    <div class="flex-one flex pl-8 pt-2 pr-8 pb-2 cursor-pointer items-center focus-within:text-xl">
                        <div class="w-full font-medium text-xl">
                            <a href="#contact" class="text-black-principal"> {{ $t('menu.contact') }}</a>
                        </div>
                    </div>
                    <div @mouseover="showAdmissionPlease(flase)"
                        class="flex-one flex pl-8 pt-2 pr-8 pb-2 cursor-pointer items-center focus-within:text-xl">
                        <div class="w-full font-medium text-xl">
                            <a href="#blog" class="text-black-principal"> {{ $t('blog.title') }}</a>
                        </div>
                    </div>
                    <div @mouseover="showAdmissionPlease(true)"
                        class="flex-one flex pl-8 pt-2 pr-8 pb-4 cursor-pointer items-center focus-within:text-xl">
                        <div class="w-full font-medium text-xl">Partnarship</div>
                        <div class=" text-3xl transform rotate-270">
                            <icon :data="icon_m" height="16" width="16" original />
                        </div>
                    </div>
                </div>

                <!-- Formation liste -->
                <div v-if="showTrannig" @mouseleave="showTranningPlease(false)" class="formation flex-grow">
                    <div class="class-item flex flex-wrap bg-white">
                        <div class="w-1/2 cursor-pointer p-4">
                            <spam @click="goDetail('digitalProjectManager')"  class="text-black-principal inline-block">{{ $t('training.digitalProjectManager') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('srit')" class="text-black-principal inline-block">{{ $t('training.srit') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('webDevelopper')"  class="text-black-principal inline-block">{{ $t('training.webDevelopper') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('bfa')" class="text-black-principal inline-block">{{ $t('training.bfa') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('ecom')" class="text-black-principal inline-block">{{ $t('training.ecom') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('audit')" class="text-black-principal inline-block">{{ $t('training.audit') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('itTech')" class="text-black-principal inline-block">{{ $t('training.itTech') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('projectManagement')" class="text-black-principal inline-block">{{ $t('training.projectManagement') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer">
                            <spam @click="goDetail('twin')" class="text-black-principal inline-block">{{ $t('training.twin') }}</spam>
                        </div>
                        <div class="w-1/2 p-4 cursor-pointer invisible">
                            <spam @click="goDetail('digitalProjectManager')" class="text-black-principal inline-block">{{ $t('training.digitalProjectManager') }}</spam>
                        </div>
                    </div>
                </div>
                <!-- Formation liste -->
                <div @mouseleave="showAdmissionPlease(false)" v-if="showAdmission"
                    class="admission-details  text-white">
                    <div class="content flex flex-col mt-x37  justify-center bg-white">
                        <div class="cursor-pointer p-4">
                            <a href="inscription" class="tex-black-principal">{{ $t('button.candidateNow') }}</a>
                        </div>
                        <div class="cursor-pointer p-4">
                            <a href="inscription" class="text-black-principal">{{ $t('button.candidateNow') }}</a>
                        </div>
                        <div class=" p-4 cursor-pointer">
                            <a href="/#carrer" class="text-black-principal">{{ $t('button.candidateDocument') }}</a>
                        </div>
                        <div class="p-4 cursor-pointer">
                            <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/rema-da2f4.appspot.com/o/brochure.pdf?alt=media&token=bf685015-b301-48e0-babf-0de37515cee5" class="text-black-principal">{{ $t('button.brochure') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Header Section -->
        <div
            class="header-section z-20 md:h-28 h-24 md:mt-11 absolute w-full bg-red-light flex items-center md:pl-16 md:pr-16 pl-10 pr-10 pt-10 pb-10">
            <div class="flex flex-grow md:gap-10 gap-4 items-center">
                <a class="image-section" href="/">
                    <img class="md:h-16 h-8" src="../../assets/images/webp/logo.webp" />
                </a>
                <div class="w-px md:h-16 h-10 bg-grey-white"></div>
                <div class="burger-section flex flex-col md:gap-3 gap-2 cursor-pointer" @click="showMenuPlease(true)">
                    <div class="h-1 md:w-12 w-10 bg-white"></div>
                    <div class="h-1 md:w-12 w-10 bg-white"></div>
                    <div class="h-1 md:w-12 w-10 bg-white"></div>
                </div>

            </div>
            <div class="contact-us text-white font-medium cursor-pointer text-xl">
                <a href="/#contact">{{ $t('header.contactUs') }}</a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ic_arrow from '../../assets/icons/ic_drop_arrow.svg'
import { useStore } from 'vuex';
const { t } = useI18n();

 
const router = useRouter()
const store = useStore();



const showMenu = ref(false);
const showTrannig = ref(false);
const showAdmission = ref(false);


const props = defineProps({
    title: {
        type: String,
        required: true,
        default: 'slider.welcomeInfutureSchool'
    }
})

// Writter text array 
const array = [t(`${props.title}`)]

// Configure autoplay
const autoPlayEnabled = ref(false);
const timeoutDuration = ref(3000);

// Current slide item;
const currentSlide = ref(1)

// All slide items
const getAllSlidesCount = ref(null);

onMounted(() => {
    // Get all element who have slide class
    getAllSlidesCount.value = document.querySelectorAll('.slide').length;
})

// Go to chose slide
// function goToSlide(position) {
//     currentSlide.value = position;
// }

// Auto play 
function autoPlay() {
    setInterval(() => {
        moveSlide()
    }, timeoutDuration.value)
}

// Auto play slide
if (autoPlayEnabled.value) {
    autoPlay();
}



// Move next or back
function moveSlide() {
    if (currentSlide.value === getAllSlidesCount.value) {
        currentSlide.value = 1
        return;
    }
    currentSlide.value++;
}

function showTranningPlease(value) {
    showTrannig.value = value;
}

function showMenuPlease(value) {
    showMenu.value = value;
}

function showAdmissionPlease(value) {
    showAdmission.value = value;
}

function goDetail(item) {
   store.dispatch('saveTrainning', item)
   router.go(router.currentRoute)
}

</script>

<style scoped>
.header-option {
    margin-left: 16.4%;
}

.menu {
    z-index: 999 !important;
}
</style>

