<template>
    <OptionPicker @close="showDoc = false" v-if="showDoc"></OptionPicker>
    <div class="tranning-detail">
       <Header :title="'formation.' + getLabel + '.title'"></Header>
    </div>
    <div class="details-block">
        <Details :title="getLabel"></Details>
    </div>
    <div class="footer-section">
        <Footer @shoWOption="showDoc = true"></Footer>
    </div>
</template>

<script setup>
import Header from "../../components/Items/Header.vue";
import Details from "../../components/Items/Details.vue";
import Footer from "../../components/globals/Footer.vue";
import { useStore } from "vuex";
import { ref } from 'vue';
import OptionPicker from "../../components/globals/OptionPicker.vue";

const store = useStore()


const getLabel = store.getters.training;

const showDoc = ref(false)


</script>

<style scoped>

</style>>