<template>
    <div class="details md:p-16 p-10">
        <div id="ddd" class="content-section flex md:flex-row flex-col gap-8">
           <div class="md:w-2/3 w-full">
            <div class="definition-section">
                <h3 class="font-bold text-2xl text-blue-principal">{{ $t('details.mission') }}</h3>
                 <p class="text-base mt-4 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.mission`)"></p>
            </div>
            <div class="advantages-section mt-8">
                <h3 class="font-bold text-2xl text-blue-principal">{{ $t('details.avantages') }}</h3>
                <p class="text-base mt-4 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.advantages`)"></p>
            </div>

            <!--- Programme section  -->
            <!-- <div class="programme-section mt-6">
                <div
                 class="header flex gap-6">
                    <div
                    @click="moveStep('training')"
                     :class="[
                    'flex-one pl-6 cursor-pointer pr-6 pt-2 pb-2 rounded-full shadow-sm',
                    step === 'training' ?  'bg-blue-principal text-white' : 'bg-grey-veryLight'
                ]">{{ $t('details.training') }}</div>
                    <div 
                    @click="moveStep('programme')"
                    :class="[
                    'flex-one pl-6 cursor-pointer pr-6 pt-2 pb-2 rounded-full shadow-sm',
                    step === 'programme' ?  'bg-blue-principal text-white' : 'bg-grey-veryLight'
                ]">{{ $t('details.programme') }}</div>
                    <div 
                    @click="moveStep('contact')"
                    :class="[
                    'flex-one pl-6 cursor-pointer pr-6 pt-2 pb-2 rounded-full shadow-sm',
                    step === 'contact' ?  'bg-blue-principal text-white' : 'bg-grey-veryLight'
                ]">{{ $t('details.contact') }}</div>
                </div>
                <div class="mt-6 rounded shadow-lg p-6 text-base leading-7">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam dignissim non eu faucibus tincidunt eleifend tellus neque tristique. Leo elit eget non diam, diam elit ipsum egestas. Cursus platea tellus pellentesque et. Imperdiet a vehicula in suspendisse vulputate enim. Lectus aliquet tincidunt arcu, ornare. Quam sed enim, posuere dictum. Mauris, velit quisque sagittis ornare habitasse. Nunc morbi cras pellentesque pellentesque aliquam dictum cras sollicitudin. Maecenas nibh rhoncus, at tincidunt adipiscing mi lorem facilisi. Posuere in pharetra nisi tristique turpis viverra egestas. Ut sit turpis aenean odio turpis est.
                </div>
            </div> -->
           </div>

           <div class="md:w-1/3 w-full">
            <div class="next-start-section shadow-lg p-6 rounded">
                <h3 class="font-bold text-2xl text-blue-principal">{{ $t('details.nextStart') }}</h3>
                <p class="text-base mt-3 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.nextStart`)"></p>
                <!-- <h3 class="font-bold text-2xl mt-6 text-red-principal">{{ $t('details.nextEvent') }}</h3>
                <p class="text-base mt-3 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.nextEvent`)"></p> -->
            </div>
            <div class="next-start-section shadow-lg p-6 rounded mt-6">
                <h3 class="font-bold text-2xl text-blue-principal">{{ $t('details.pricing') }}</h3>
                <p class="text-base mt-3 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.pricing`)"></p>
            </div>
            <div class="next-start-section shadow-lg p-6 rounded mt-6">
                <h3 class="font-bold text-2xl text-blue-principal">{{ $t('details.opportunities') }}</h3>
                <p class="text-base mt-3 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.opportunities`)"></p>
                <!-- <h3 class="font-bold text-2xl mt-6 text-red-principal">{{ $t('details.compensation') }}</h3>
                <p class="text-base mt-3 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.compensation`)"></p> -->
                <!-- <h3 class="font-bold text-2xl mt-6 text-red-principal">{{ $t('details.nextStudies') }}</h3>
                <h3 class="text-base font-semibold mt-1"> {{ $t("details.inSchool") }} </h3>
                <p class="text-base mt-1 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.inSchoolNext`)"></p>
                <h3 class="text-base font-semibold mt-4"> {{ $t("details.outSchool") }} </h3>
                <p class="text-base mt-1 text-black-principal font-normal leading-7 list-disc" v-html="$t(`formation.${title}.outSchoolNext`)"></p> -->
            </div>
           </div>
        </div>
    </div>
</template>

<script setup>
    import {defineProps} from 'vue';

const props = defineProps({
    training: {
        type: Object,
        required: true,
        default () {
            return {
                mission: 'formation.digitalProjectManager.mission',
                advantages: 'formation.digitalProjectManager.advantages',
                nextStart: 'formation.digitalProjectManager.nextStart',
                nextEvent: 'formation.digitalProjectManager.nextEvent',
                pricing: 'formation.digitalProjectManager.pricing',
                opportunities: 'formation.digitalProjectManager.opportunities',
                compensation: 'formation.digitalProjectManager.compensation',
                inSchoolNext: "formation.digitalProjectManager.inSchoolNext",
                outSchoolNext: "formation.digitalProjectManager.outSchoolNext"
            }
        }
    },
    title: {
        type: String,
        required: true,
    }
})
console.log(props);

// let step = ref('training')

// function moveStep(value) {
//     step.value = value;
// }

</script>

<style scoped>

</style>
